import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';

export default {
  extends: TablePage,
  props: {
    treeCode: String,
    treeName: String,
  },
  data() {
    return {
      params: {
        directSystemId: '',
        directSystemName: '',
      },
      configs: {
        formConfig: {
          item: [
          ],
        },
      },
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  components: {
    Modal,
  },
  watch: {
    treeCode(val) {
      this.params.directSystemId = val;
      this.params.directSystemName = this.treeName;
      this.getList();
    },
  },
  created() {
    this.getConfigList('login_log_main_list');
  },
  methods: {
    setColumn(v) {
      const rowData = v;
      return rowData;
    },
    beforeModalClick({ val }) {
      return true;
    },
    modalClick({ val, row }) {},
  },
};
